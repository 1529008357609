import React, { useEffect, useMemo, useState } from "react";
import MaxContent from "../layout/MaxContent";
import { graphql, Link, navigate } from "gatsby";
import {
  categoryList,
  IBlog,
} from "../components/Articles/Article_Listing/ArticleListing";
import {
  FeaturedBlog,
  Blog,
} from "../components/Articles/Article_Listing/ArticleListing";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navigation/Navbar";
import SeoHelmet from "../components/Seo/SeoHelmet";
import TertiaryHeader from "../components/Headers/TertiaryHeader";
import PromoBanner from "../components/Banners/PromoBanner";
import IconsRow from "../components/IconsRow/IconsRow";
import useBlogSearch from "../helpers/useBlogSearch";
import { SEO } from "../components/Seo/SeoHelmet";
import { BlogLandingContext } from "../components/Types";
import { DropDown } from "get-life-storybook-ts";

const BackArrow =
  "https://storage.googleapis.com/getlife-bucket-1/public/image/cms/blue-back-arrow.svg";

export default (data: any) => {
  const pageData = data.data.strapiBlogLandingPage;
  const pageContext: BlogLandingContext = data.pageContext; // BlogLandingPageContext
  const navbarComponent = pageData.Navbar;
  const seoComponent = pageData.SEO;
  const promoBannerComponent = pageData.Banner;
  const iconRowComponent = pageData.IconsRow;
  const footerComponent = pageData.Footer;

  const [category, setCategory] = useState<string>(
    pageContext.category ?? categoryList[process.env.GATSBY_APP_LOCALE!][0]
  );
  const [query, setQuery] = useState<string>();
  const [blogs, setBlogs] = useState<Array<IBlog> | null>(pageContext.blogs);
  const { search } = useBlogSearch(pageContext.allBlogs);

  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    if (pageContext.category) {
      setCategory(pageContext.category);
    }
    setMounted(true);
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      const params = new URLSearchParams(window.location.search);

      if (params.has("q")) {
        const queryParam = params.get("q");

        if (queryParam) {
          setQuery(queryParam);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (query) {
      const queryResults = search(query);
      setBlogs(queryResults);
    }
  }, [query]);

  function injectSEO(data: SEO) {
    const SEODataES = {
      "Todos": {
        title: "Blog Life5 - La mejor información sobre el sector seguros",
        description: `Más de 350 artículos publicados y actualizados diariamente por expertos en seguros de vida, ahorro, fiscalidad y finanzas.`,
      },
      "Fiscalidad": {
        title: "Claves de fiscalidad y tributación en España - Blog Life5",
        description: `Navega por el mundo de los impuestos con facilidad. Conoce tus obligaciones y derechos fiscales. ¡Haz clic para saber más!`,
      },
      "Actualidad": {
        title: "Últimas noticias sobre seguros de vida - Blog Life5",
        description: `Mantente al día de la actualidad aseguradora y entiende qué implican para ti las últimas novedades.`,
      },
      "Ahorro": {
        title: "Guías y consejos para máximizar el ahorro - Blog Life5",
        description: `Aprende a ahorrar de manera efectiva y descubre estrategias para una economía personal óptima.`,
      },
      "Seguros": {
        title: "Claves de seguros, sus finalidades y coberturas - Blog Life5",
        description: `Averigua cómo los seguros pueden proteger tu futuro. Desgranamos todos los tipos para que tomes la mejor decisión. ¡Infórmate ahora!`,
      },
      "Educacion_financiera": {
        title: "Educación financiera: consejos para todos - Blog Life5",
        description: `Aprende a gestionar tus finanzas personales desde ya ¡Piensa en tu "yo" futuro!`,
      },
      "Life_Talks": {
        title: "Charlas sobre seguros, inversiones y economía - Blog Life5",
        description: `Únete a nuestras conversaciones en Life Talks. Consigue insights sobre finanzas y más. ¡Participa en el diálogo!`,
      }
    }

    const SEODataFR = {
      "Toutes": {
        title: "Blog Life5 - Les meilleures informations sur le secteur de l'assurance",
        description: `Informations pratiques publiées et mises à jour par des experts en assurance-vie, épargne, fiscalité et finance.`
      },
      "Conseil_et_fiches_pratiques": {
        title: "Conseils Pratiques en Assurances et Finance Personnelle - Blog Life5",
        description: `Découvrez des astuces pour gérer vos assurances décès, économiser au quotidien et optimiser vos finances personnelles. Conseils experts Life5.`
      },
      "Decryptage": {
        title: "Analyse Approfondie du Secteur des Assurances - Blog Life5",
        description: `Explorez nos analyses détaillées sur les assurances, les tendances du marché et les implications légales. Éclairage expert avec Life5.`
      },
      "Edito_et_actus": {
        title: "Perspectives d'Experts sur l'Assurance et la Finance - Blog Life5",
        description: `Nos éditoriaux offrent des vues uniques sur l'assurance, vos finances personnelles et les tendances du marché. Découvrez des idées novatrices.`
      },
      "Life5": {
        title: "Avancées et Solutions en Assurance - Blog Life5",
        description: `Suivez les dernières innovations de Life5 en assurance et finance. Découvrez comment nous façonnons l'avenir du secteur.`
      },
      "Tech": {
        title: "Innovations en Assurance: Technologie et Tendances - Blog Life5",
        description: `Plongez dans l'intersection de la technologie et des assurances. Découvrez comment la fintech et l'insurtech redéfinissent le secteur.`
      },
    }

    const SEOData = {
      es: SEODataES,
      fr: SEODataFR
    }[process.env.GATSBY_APP_LOCALE];

    let title = (SEOData && SEOData[category]?.title) ?? data.SeoTitle;
    let description = (SEOData && SEOData[category]?.description) ?? data.SeoDescription;

    if (pageContext.currentPage > 0) {
        title = `${title} - Page ${pageContext.currentPage + 1}`;
    }

    const injected: SEO = { ...seoComponent };
    injected.SeoTitle = title;
    injected.SeoDescription = description;

    return injected;
  }

  const seoMemo = useMemo(() => injectSEO(seoComponent), [seoComponent]);

  const optionsCategories:Record<string, any> = {
    es: [
      { label: "Todos", value: "Todos" },
      { label: "Fiscalidad", value: "Fiscalidad" },
      { label: "Actualidad", value: "Actualidad" },
      { label: "Ahorro", value: "Ahorro" },
      { label: "Seguros", value: "Seguros" },
      {
        label: "Educacion financiera",
        value: "Educacion_financiera",
      },
      { label: "Life Talks", value: "Life_Talks" },
    ],
    fr: [
      { label: "Toutes", value: "Toutes" },
      { label: "Conseil", value: "Conseil_et_fiches_pratiques" },
      { label: "Decryptage", value: "Decryptage" },
      { label: "Edito et actus", value: "Edito_et_actus" },
      { label: "Life5", value: "Life5" },
      { label: "Tech financiera", value: "Tech" },
    ],
    pt: [
      { label: "Toutes", value: "Toutes" },
      { label: "Conseil", value: "Conseil_et_fiches_pratiques" },
      { label: "Decryptage", value: "Decryptage" },
      { label: "Edito et actus", value: "Edito_et_actus" },
      { label: "Life5", value: "Life5" },
      { label: "Tech financiera", value: "Tech" },
    ],
    it: [
      { label: "Toutes", value: "Toutes" },
      { label: "Conseil", value: "Conseil_et_fiches_pratiques" },
      { label: "Decryptage", value: "Decryptage" },
      { label: "Edito et actus", value: "Edito_et_actus" },
      { label: "Life5", value: "Life5" },
      { label: "Tech financiera", value: "Tech" },
    ],
    axa_wl_es: [
      { label: "Todos", value: "Todos" },
      { label: "Fiscalidad", value: "Fiscalidad" },
      { label: "Actualidad", value: "Actualidad" },
      { label: "Ahorro", value: "Ahorro" },
      { label: "Seguros", value: "Seguros" },
      {
        label: "Educacion financiera",
        value: "Educacion_financiera",
      },
      { label: "Life Talks", value: "Life_Talks" },
    ],
  }

  const SEOHelmetData = {
    es: seoMemo,
    fr: seoMemo
  }[process.env.GATSBY_APP_LOCALE] || seoComponent;

  return (
    <>
      {/* @ts-ignore */}
      <SeoHelmet
        data={SEOHelmetData}
        prev={pageContext.prevPage}
        next={pageContext.nextPage}
        customUrl={pageContext.actualPage}
        locale={pageData.locale}
      />
      {navbarComponent && <Navbar data={navbarComponent} />}

      <TertiaryHeader
        data={
          query
            ? { Title: `${pageData.SearchResultLabel} ${query}` }
            : pageData.Header
        }
      />
      {query && (
        <MaxContent size="content">
          <button
            onClick={() => navigate(-1)}
            className="no-underline flex flex-row items-center gap-[15px] w-fit bg-transparent shadow-none text-purple border-none text-link-lg font-bold pl-0"
          >
            <img
              src={BackArrow}
              className="w-[19px] h-[15px]"
              alt="BackArrow"
            />
            <>Blogs</>
          </button>
        </MaxContent>
      )}

      <MaxContent size="content">
        {/* CATEGORY BUTTONS */}
        {!query && (
          <div className="-mt-[50px] md:mt-[50px]">
            <div className="hidden md:flex flex-row flex-wrap gap-3">
            {categoryList[process.env.GATSBY_APP_LOCALE!].map((cat: string, index: number) => {
                return (
                  <CategoryButton
                    label={cat}
                    key={cat}
                    index={index}
                    category={category}
                  />
                );
              })}
            </div>

            <div className="block md:hidden pt-[16px] [&>div]:px-0">
              <DropDown
                initialValue={category}
                onValueChange={(value) => {
                  const sanitizedCategorySlug = `${value
                    .toLowerCase()
                    .replace(/_/g, "-")}`;
                  if (isMounted) {
                    if (value === categoryList[process.env.GATSBY_APP_LOCALE!][0]) {
                      navigate("/blog");
                    } else if (value === "Conseil_et_fiches_pratiques") {
                      navigate(`/blog/conseils/`);
                    } else if (value === "Edito_et_actus") {
                      navigate(`/blog/edito/`);
                    } else {
                      navigate(`/blog/${sanitizedCategorySlug.toLowerCase()}`);
                    }
                  }
                }}
                options={optionsCategories[process.env.GATSBY_APP_LOCALE!]}
              />
            </div>
          </div>
        )}

        {!query && pageContext.featured && pageContext.featured[0] && (
          <div className="flex w-full my-[34px]">
            <FeaturedBlog {...pageContext.featured[0]} />
          </div>
        )}

        <div
          className={`grid grid-rows-1 md:grid-cols-3 gap-[20px] 
          mb-[70px] md:mb-[80px] ${pageContext.featured ? "" : "mt-[34px]"} `}
        >
          {blogs &&
            blogs.map((blog: IBlog, index: number) => {
              return (
                <div key={index + "-" + blog.Title}>
                  <div className="md:hidden w-full h-[1px] bg-[#E2E2E2]" />
                  <Blog {...blog} />
                  {blogs.length - 1 === index && (
                    <div className="md:hidden w-full h-[1px] bg-[#E2E2E2]" />
                  )}
                </div>
              );
            })}
        </div>

        {!query && (
          <div className="flex justify-center text-center w-full gap-3 flex-wrap mb-[70px] md:mb-[100px]">
            {pageContext.pageCount &&
              Array.from(Array(pageContext.pageCount).keys()).map(
                (_, index) => {
                  const sanitizedCategorySlug = `${pageContext.category
                    .toLowerCase()
                    .replace(/_/g, "-")}`;
                  let url = `/blog/${sanitizedCategorySlug}/${index === 0 ? "" : `page/${index + 1}/`
                    }`;

                  if (pageContext.category === categoryList[process.env.GATSBY_APP_LOCALE!][0]) {
                    url = `/blog/${index === 0 ? "" : `page/${index + 1}/`}`;
                  }

                  if (pageContext.category === "Conseil_et_fiches_pratiques") {
                    url = `/blog/conseils/${index === 0 ? "" : `page/${index + 1}/`
                      }`;
                  }

                  if (pageContext.category === "Edito_et_actus") {
                    url = `/blog/edito/${index === 0 ? "" : `page/${index + 1}/`
                      }`;
                  }

                  return (
                    <Link
                      key={"pagenumber-" + index}
                      to={url}
                      className={`cursor-pointer no-underline ${pageContext.currentPage === index
                          ? "text-purple"
                          : "text-black"
                        }`}
                    >
                      {index + 1}
                    </Link>
                  );
                }
              )}
          </div>
        )}
      </MaxContent>

      {promoBannerComponent && <PromoBanner data={promoBannerComponent} />}
      {iconRowComponent && <IconsRow data={iconRowComponent} />}
      {footerComponent && <Footer data={footerComponent} />}
    </>
  );
};

const CategoryButton = ({ label, category }: any) => {
  const sanitizedCategorySlug = `${label.toLowerCase().replaceAll("_", "-")}`;
  let url = `/blog/${sanitizedCategorySlug}/`;

  if (label === categoryList[process.env.GATSBY_APP_LOCALE!][0]) {
    url = `/blog/`;
  }

  if (label === "Conseil_et_fiches_pratiques") {
    url = `/blog/conseils/`;
  }

  if (label === "Edito_et_actus") {
    url = `/blog/edito/`;
  }

  return (
    <Link
      to={url}
      className={`md:min-w-fit py-[17px] px-[30px] rounded cursor-pointer no-underline
      ${category === label
          ? "bg-purple text-white"
          : "border-[1px] border-[#E2E2E2]"
        }`}
    >
      <p className="text-nowrap">{label.replaceAll("_", " ")}</p>
    </Link>
  );
};

export const blogQuery = graphql`
  query blogListQuery {
    strapiBlogLandingPage {
      SearchResultLabel
      ResultsNotFoundLabel
      locale
      SEO {
        SeoTitle
        SeoDescription
        DoNotIndex
        DoNotFollow
        Schema
        CanonicalTag
        TwitterImageUrl
        FacebookImageUrl
        ImageUrl
        Image {
          file {
            childImageSharp {
              gatsbyImageData
            }
            url
          }
        }
        Links {
          HrefLang
          Href
        }
      }
      Navbar {
        DropdownLabel
        DropdownLinks {
          Label
          Url
        }
        Links {
          Label
          Url
        }
        Phone
        HideAccount
        HidePhone
        AccountLabel
        AccountIcon {
          file {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        AccountUrl
        ButtonLabel
        ButtonUrl
        idButton
      }
      Footer {
        group_title_1
        group_title_2
        group_title_3
        group_title_4
        group_links_1 {
          Label
          Url
        }
        group_links_2 {
          Label
          Url
        }
        group_links_3 {
          Label
          Url
        }
        group_links_4 {
          Label
          Url
        }
        group_links_bottom {
          Label
          Url
        }
        contact_address
        contact_phone_number
        contact_phone_subtitle
        contact_title
        contact_map_link {
          Label
          Url
          id
        }
        form_header
        form_submit_button_link {
          Label
          Url
        }
        form_text_placeholder
        facebook_url
        instagram_url
        linkedin_url
        twitter_url
      }
      Header {
        Title
      }
      Banner {
        ButtonLabel
        ButtonUrl
        SelectTitleType {
          TitleTypeSelection
        }
        SubTitle
        Title
      }
      IconsRow {
        IconsList {
          SingleImage {
            file {
              childImageSharp {
                gatsbyImageData
              }
            }
            alternativeText
          }
        }
      }
    }
  }
`;

import * as JsSearch from "js-search";
import { IBlog } from '../components/Articles/Article_Listing/ArticleListing'

type ReturnType = {
  search: (q: string) => IBlog[];
};

export default function useJsSearch(blogs: IBlog[]): ReturnType {
  // Search configuration
  const dataToSearch = new JsSearch.Search("id");
  dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
  dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
  dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");

  // Fields to search
  dataToSearch.addIndex('Title');
  dataToSearch.addIndex('Description');
  dataToSearch.addIndex('Category');

  // create nodes
  dataToSearch.addDocuments(blogs);

  const search = (query: string) => dataToSearch.search(query) as IBlog[]

  return { search };
}